<template>
	<div class="oa-wrapper">
		<div class="oa-modal">
			<div class="oa-modal__headline">
				<p class="oa-modal__name">{{ name }}</p>
				<span class="oa-modal__close" @click="$emit('closeModal')" />
			</div>
			<ul class="oa-modal__list">
                <slot />
			</ul>
		</div>
	</div>
</template>
<script>

export default {
    name: "ModalElement",
	props: ["name"],
}
</script>

