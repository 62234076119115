<template>
	<header class="oa-header" :class="{ 'oa-header--hide': splashScreen }">
		<CompanyBrand :customerName="customerName" />
		<img @click="showLandingScreen()" class="oa-header__info"
			src="https://ichemistrystaticcdn.blob.core.windows.net/ichemistry-static-files/openaccess/question-mark-circle.svg"
			alt="info" />
		<p class="oa-header__department">{{ currentLanguage.department }}</p>
		<div class="oa-header__search">
			<h1 class="oa-header__headline">
				{{ departmentData.name }}
				<DepartmentPath :path="departmentData.nodeNamePath" />
			</h1>
			<IconButton @click="triggerSearch" />
		</div>
	</header>
	<main class="oa-main" :class="{ 'oa-main-mobile': isMobile, 'oa-main--hide': splashScreen }">
		<Tabs @switchTab="switchTab" :tabs="tabs">
			<ul v-if="currentTab === this.currentLanguage.products && !!productData" class="oa-list">
				<li class="oa-list__item" v-for="(item, ind) in productData" :key="ind" @click="openProperties(item)">
					<Icon class="oa-icon--flask" />
					<div class="oa-list__item__content">
						<h3 class="oa-list__item__headline">{{ item.artNo }}</h3>
						<p class="oa-list__item__name">{{ item.name }}</p>
					</div>
					<footer class="oa-list__item__footer oa-list__item__footer--supplier">{{ item.supplier }}</footer>
				</li>
			</ul>
			<ul v-if="currentTab === this.currentLanguage.activities && !!activityData" class="oa-list">
				<li class="oa-list__item" v-for="(item, ind) in activityData" :key="ind" @click="openProperties(item)">
					<Icon />
					<div class="oa-list__item__content">
						<DepartmentPath
							:path="item.locationPath"
							:name="item.locationName"
						/>
						<p class="oa-list__item__name">{{ item.name }}</p>
					</div>
					<footer class="oa-list__item__footer" v-html="splitResult(item.productName)" />
				</li>
			</ul>
			<ul v-if="currentTab  === this.currentLanguage.wpi && wpiData?.length > 0" class="oa-list">
				<li class="oa-list__item" v-for="(item, ind) in wpiData" :key="ind" @click="openProperties(item)">
					<Icon class="oa-icon--wpi" />
					<div class="oa-list__item__content">
						<DepartmentPath
							:path="item.locationPath"
							:name="item.locationName"
						/>
						<p class="oa-list__item__name">{{ item.name }}</p>
					</div>
					<footer class="oa-list__item__footer" v-html="splitResult(item.productName)" />
				</li>
			</ul>
		</Tabs>
		<Loader v-if="currentTab === this.currentLanguage.products && showLoaderProducts" />
		<Loader v-if="currentTab === this.currentLanguage.activities && showLoaderActivities" />
		<Loader v-if="currentTab  === this.currentLanguage.wpi && showLoaderWpi" />
		<ResultsInfo v-if="currentTab === this.currentLanguage.products && !showLoaderProducts && productData?.length === 0"

			img="https://ichemistrystaticcdn.blob.core.windows.net/ichemistry-static-files/openaccess/products-flask.svg"
			title="No products" text="There are no products on this department." alt="icon" />
		<ResultsInfo v-if="currentTab === this.currentLanguage.activities && !showLoaderActivities && activityData?.length === 0"
			img="https://ichemistrystaticcdn.blob.core.windows.net/ichemistry-static-files/cdn-assets/open-access/missing-activities.svg"
			title="No activities" text="There are no activities on this department." alt="icon" />
		<ResultsInfo
			v-if="currentTab  === this.currentLanguage.wpi  && !showLoaderWpi && wpiData?.length === 0"
			img="https://ichemistrystaticcdn.blob.core.windows.net/ichemistry-static-files/cdn-assets/open-access/no-wpis.svg"
			title="No WPI found" text="There are no work place instructions on this department." alt="icon" />
	</main>
	<LandingScreen v-model:customerName="customerName" @closeLandingScreen="closeLandingScreen()"
		@hideLandingScreen="hideLandingScreen()" :class="{ 'oa-landing-screen--show': landingScreen }" />
	<SplashScreen :class="{ 'oa-splash-screen--show': splashScreen }" />
	<Search ref="searchRef" @closeSearch="openSearch = !openSearch" @openProperties="openProperties($event)" :products="productData"
		:activities="activityData" :wpis="wpiData" :placeholder="tabs.length === 2
			? currentLanguage.searchForProductsAndActivities
			: currentLanguage.searchForProductsActivitiesOrWPIs
		" :show="openSearch" :showActivities="showActivitiesData" :showWpi="showWpiData" />
	<PlainSearch ref="searchActivityProductsRef" @closeSearch="showActivityProductsSearch = !showActivityProductsSearch" @openProperties="openProperties($event)" :products="activityProductsList"
		:activities="[]" :wpis="[]"  :show="showActivityProductsSearch" :data="propertiesData" />
	<Transition name="modal">
		<Properties
			v-if="showProperties"
			:data="propertiesData"
			:token="token"
			:showButtonSafetySheet="showButtonSafetySheet"
			:showButtonWpiDownload="showWpiData"
			:department="departmentData.name"
			@openPlainSearch="openPlainSearch"
			@activityProductList="activityProductsList = $event"
			@close="closeProperties()"
		/>
	</Transition>
</template>

<script>
import CompanyBrand from "@components/common/CompanyBrand.vue";
import DepartmentPath from "@components/DepartmentPath.vue";
import Icon from "@components/common/Icon.vue";
import IconButton from "@components/common/IconButton.vue";
import LandingScreen from "@components/common/LandingScreen";
import Loader from "@components/common/Loader.vue";
import Properties from "@components/common/Properties.vue";
import ResultsInfo from "@components/ResultsInfo.vue";
import Search from "@components/Search.vue";
import PlainSearch from "@components/PlainSearch.vue";
import SplashScreen from "@components/common/SplashScreen.vue";
import Tabs from "@components/tab/Tabs.vue";
import axios from "../axios";
import router from "../main";
import useTranslations from "@modules/translations.js";

export default {
	name: "MainPage",
	setup() {
		const { currentLanguage, changeLanguage } = useTranslations();
		let browserLang = navigator.languages.at(0);
		changeLanguage(browserLang);
		return { currentLanguage };
	},
	components: {
		CompanyBrand,
		DepartmentPath,
		Icon,
		IconButton,
		LandingScreen,
		Loader,
		Properties,
		ResultsInfo,
		Search,
		SplashScreen,
		Tabs,
		PlainSearch
	},
	data() {
		return {
			showProperties: false,
			showActivitiesData: false,
			showWpiData: false,
			departmentData: {},
			propertiesData: null,
			productData: [],
			activityData: [],
			wpiData: [],
			openSearch: false,
			currentTab: this.currentLanguage.products,
			showLoaderProducts: false,
			showLoaderActivities: false,
			showLoaderWpi: false,
			showActivityProductsSearch: false,
			showButtonSafetySheet: false,
			activityProductsList: [],
			token: null,
			languages: {
				en: { nativeName: "English" },
				sv: { nativeName: "Swedish" },
				da: { nativeName: "Danish" },
				fi: { nativeName: "Finnish" },
				no: { nativeName: "Norwegian" },
			},
			splashScreen: true,
			landingScreen: true,
			initialTabs: [
				{
					name: this.currentLanguage.products,
					numberOfItems: 0,
					order: 1
				},
			],
		};
	},
	methods: {
		openProperties(item) {
			this.showProperties = true;
			this.propertiesData = item;
		},
		closeProperties() {
			this.showProperties = false;
			this.propertiesData = null;
		},
		closeLandingScreen() {
			localStorage.setItem("hasShownLandingScreen", "false");
			this.landingScreen = false;
		},
		getData() {
			this.getProducts();
			this.getActivities();
			this.getDepartment();
			this.getWPI();
		},
		switchTab(tabName) {
			this.currentTab = tabName;
		},
		openPlainSearch() {
			this.showProperties = false;
			this.showActivityProductsSearch = true;
			this.$refs.searchActivityProductsRef.focusSearch();
		},
		getProducts() {
			this.showLoaderProducts = true;

			const config = {
				headers: {
					"open-access-token": this.token,
				},
			};

			axios
				.get("/api/Products", config)
				.then((response) => {
					this.showLoaderProducts = false;
					if (response.data) {
						this.productData = [
							...response.data.map((f) => ({ ...f, isProduct: true })),
						];

						this.initialTabs.find(t => t.name === this.currentLanguage.products).numberOfItems = this.productData.length;
					}
				})
				.catch(function (error) {
					console.log(error);
					router.push("unavailable");
				});
		},
		getActivities() {
			const config = {
				headers: {
					"open-access-token": this.token,
				},
			};

			axios
				.get("/api/activities-available", config)
				.then((response) => {
					this.showActivitiesData = response.data;

					if (this.showActivitiesData) {

						this.initialTabs.push({
							name: this.currentLanguage.activities,
							numberOfItems: 0,
							order: 2,
						});

						this.initialTabs.sort((a, b) => a.order < b.order);

						this.showLoaderActivities = true;

						axios
							.get("/api/Activities", config)
							.then((response) => {
								this.showLoaderActivities = false;

								if (response.data) {
									this.activityData = [
										...response.data.map((f) => ({ ...f, isActivity: true })),
									];

									this.initialTabs.find(t => t.name === this.currentLanguage.activities).numberOfItems = this.activityData.length;

								}
							})
							.catch(function (error) {
								console.log(error);
								router.push("unavailable");
							});
					}

				})
				.catch(function (error) {
					console.log(error);
					router.push("unavailable");
				});

		},
		getWPI() {
			const config = {
				headers: {
					"open-access-token": this.token,
				},
			};

			axios
				.get("/api/wpi-available", config)
				.then((response) => {
					//this.showWpiData = response.data;
					this.showWpiData = true;

					if (this.showWpiData) {

						this.initialTabs.push({
							name: this.currentLanguage.wpi,
							numberOfItems: 0,
							order: 3,
						});

						this.initialTabs.sort((a, b) => a.order < b.order);

						this.showLoaderWpi = true;

						axios
							.get("/api/WPI", config)
							.then((response) => {
								this.showLoaderWpi = false;
								if (response.data) {

									this.wpiData = [
										...response.data.map((f) => ({ ...f, isWpi: true })),
									];

									this.initialTabs.find(t => t.name === this.currentLanguage.wpi).numberOfItems = this.wpiData.length;
								}
							})
							.catch(function (error) {
								console.log(error);
								router.push("unavailable");
							});
					}
				})
				.catch(function (error) {
					console.log(error);
					router.push("unavailable");
				});
		},
		getDepartment() {
			const config = {
				headers: {
					"open-access-token": this.token,
				},
			};

			axios
				.get("/api/Departments", config)
				.then((response) => {
					this.departmentData = response.data;
				})
				.catch(function () {
					router.push("unavailable");
				});
		},
		getSafetySheetButtonAvailability() {
			const config = {
				headers: {
					"open-access-token": this.token,
				},
			};

			axios
				.get("/api/buttonsafetysheet-available", config)
				.then((response) => {
					this.showButtonSafetySheet = response.data;
				})
				.catch(function (error) {
					console.log(error);
					router.push("unavailable");
				});

		},
		hideSplashScreen() {
			this.splashScreen = false;
		},
		showLandingScreen() {
			this.landingScreen = true;
		},
		hideLandingScreen() {
			this.landingScreen = false;
		},
		triggerSearch() {
			this.openSearch = !this.openSearch;
			this.$refs.searchRef.focusSearch();
		},
		splitResult(productName) {
			const regex = /,(?![^(]*\))/;
			const items = productName.split(regex);

			return `<span class="oa-list__item__icon"></span>${items.join(',<span class="oa-list__item__icon"></span>')}`;
		}
	},
	computed: {
		tabs() {
			return [...this.initialTabs].sort((a, b) => a.order - b.order);
		},
		customerName() {
			return this.departmentData.customerName
				? this.departmentData.customerName
				: "";
		},
	},
	created() {
		setTimeout(() => {
			this.hideSplashScreen();
		}, 3000);
		// Get the item from the local storage (landing screen related)
		const hasShownLandingScreen = localStorage.getItem("hasShownLandingScreen");

		// Check if value is already set.
		if (!hasShownLandingScreen) {
			this.landingScreen = true;
			localStorage.setItem("hasShownLandingScreen", "true");
		} else {
			this.landingScreen = false;
		}
	},
	mounted() {
		this.token = this.$route.params.token;
		if (this.token) {
		    this.getSafetySheetButtonAvailability();
			this.getData();
		}
		else {
			router.push("unavailable");
		}
	},
	watch: {
		$route() {
			this.token = this.$route.params.token;
			if (this.token) this.getData();
			else router.push("unavailable");
		},
	},
};
</script>

<style>
.oa-header {
	position: relative;
	padding: 24px;
	width: 100%;
	max-width: 1054px;
	margin: 0 auto;
	transition: opacity 0.3s ease-out;
	opacity: 1;
	pointer-events: all;
}

.oa-header.oa-header--hide,
.oa-main.oa-main--hide {
	opacity: 0;
	pointer-events: none;
}

.oa-header__logo {
	margin-bottom: 12px;
}

.oa-header__info {
	position: absolute;
	right: 24px;
	cursor: pointer;
}

.oa-header__department {
	font-size: 14px;
	line-height: 20px;
	margin-top: 16px;
	color: var(--color-brand-secondary);
}

.oa-header__headline {
	display: flex;
	align-items: center;
	gap: 4px;
	font-size: 18px;
	line-height: 24px;
	font-weight: 700;
	color: var(--color-white);
	margin: 0;
}

.oa-header__headline .oa-department-path__location {
	translate: 0% 9px
}

.oa-header__search {
	display: flex;
	justify-content: space-between;
}

.oa-main {
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow: auto;
	width: 100%;
	height: 100%;
	max-width: 1054px;
	margin: 0 auto;
	padding: 0px 16px;
	background-color: var(--color-gray-100);
	border-radius: 16px 16px 0px 0px;
	transition: opacity 0.3s ease-out;
	opacity: 1;
	pointer-events: all;
}

.oa-main:after {
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 64px;
	background: linear-gradient(180deg,
			rgba(255, 255, 255, 0) 0%,
			rgba(255, 255, 255, 0.64) 100%);
	filter: drop-shadow(0px -24px 100px #ffffff);
	pointer-events: none;
}

.oa-list {
	position: relative;
	overflow-y: auto;
	overflow-x: hidden;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.oa-list::-webkit-scrollbar {
	display: none;
}

.oa-list__item {
	display: flex;
	flex-wrap: wrap;
	background-color: var(--color-white);
	padding: 16px;
	border-radius: 16px;
	margin-bottom: 12px;
	cursor: pointer;
}

.oa-list__item__content {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: space-around;
	padding-right: 24px;
	background-image: url("https://ichemistrystaticcdn.blob.core.windows.net/ichemistry-static-files/cdn-assets/open-access/chevron-right.svg");
	background-repeat: no-repeat;
	background-position: right center;
	overflow: hidden;
}

.oa-list__item__headline {
	align-self: baseline;
	margin: 0;
	max-width: 97%;
	color: var(--color-gray-500);
	font-size: 12px;
	line-height: 16px;
	font-weight: 400;
	word-break: break-word;
}

.oa-list__item__name {
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: var(--color-gray-900);
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.oa-list__item__footer {
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin-top: 16px;
	padding-top: 16px;
	border-top: 1px solid var(--color-gray-100);
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	color: var(--color-gray-500);
}

.oa-list__item__icon {
	display: inline-block;
	width: 16px;
	height: 16px;
	margin-right: 4px;
	margin-bottom: -4px;
	background-image: url('https://ichemistrystaticcdn.blob.core.windows.net/ichemistry-static-files/svg/flask.svg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: 16px;
	filter: invert(74%) sepia(20%) saturate(299%) hue-rotate(175deg) brightness(84%) contrast(89%);
}

.oa-list__item__icon:not(:first-child) {
	margin-left: 8px;
}

.oa-list__item__footer--supplier {
	background-image: url('https://ichemistrystaticcdn.blob.core.windows.net/ichemistry-static-files/cdn-assets/open-access/package-box.svg');
	background-size: 16px;
	background-repeat: no-repeat;
	background-position: left bottom;
	padding-left: 24px;
}

/* ANIMATIONS */
.modal-enter,
.modal-leave-to {
	opacity: 0;
	transition: 0.2s opacity ease-out;
}

.modal-enter-active {
	transition: 0.2s opacity ease-out;
}

.modal-enter-from {
	opacity: 0;
	transition: 0.2s opacity ease-out;
}
</style>